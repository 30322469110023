<template>
  <v-container fluid fill-height>
    <v-layout align-top justify-center class="mt-login">
      <v-flex xs10 lg4 sm6>
        <v-img
          :src="iconeAdmin"
          height="150px"
          contain
          position="top"
          class="mb-3"
        />
        <perseu-card cardClass="rounded-card" title="Login - Área restrita">
          <template slot="title-right">
            <env-chip />
          </template>
          <v-flex slot="content">
            <v-alert
              v-show="alertMsg"
              dismissible
              type="error"
              class="text-xs-center"
            >
              {{ alertMsg }}
            </v-alert>
            <v-form>
              <v-text-field v-model="user.mail" label="E-mail" />
              <v-text-field
                v-model="user.password"
                type="password"
                label="Senha"
                @keyup.enter="login"
              />
            </v-form>
          </v-flex>
          <v-flex slot="actions">
            <v-btn @click="login" color="secondary">Entrar</v-btn>
          </v-flex>
        </perseu-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { login } from "@/services/auth-service";
export default {
  data: () => ({
    user: {},
    alertMsg: "",
  }),
  computed: {
    iconeAdmin() {
      return require("@/assets/icone-admin.png");
    },
  },
  methods: {
    async login() {
      try {
        this.$store.dispatch("loading/openDialog");
        let { data } = await login(this.user);
        this.$store.dispatch("auth/login", data);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 10px;
}
</style>
